import React from "react"
import { Link } from "gatsby"
import * as containerStyles from "~/components/pages/blog/blog.module.scss"

export default function TagList ({ type, tags }) {
  return (
    <ul className={containerStyles.articleTags + " mincho"}>
      {tags.map(({ uri, slug, name }) => (
        <li key={uri}>
          <Link to={`/${type}/${slug}`}>#{name}</Link>
        </li>
      ))}
    </ul>
  )
}